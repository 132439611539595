import { useCallback } from "react";
import useHomePageHero from "../../hooks/contentful/useHomePageHero";
import useHomePageHeroPromotion from "../../hooks/contentful/useHomePageHeroPromotion";
import intl from "../../services/intl";
import metrics from "../../utils/metrics";
import PageHero from "../PageHero";
import HeroContent, {
  DangerousHeadline,
  renderHomePageHeroContent,
  validTheme,
} from "./HeroContent";
import FloatingPillHero from "./FloatingPillHero";
import useVariation from "../../hooks/useVariation";

const hasThreeLines = (title: string) =>
  title.match(/<div>(.+)<\/div>(.+)<div>(.+)<\/div>/);

type HomePageHero = ReturnType<typeof useHomePageHero>;
type HomePageHeroPromotion = NonNullable<
  ReturnType<typeof useHomePageHeroPromotion>
>;

const defaultInternals = (hero: HomePageHero) => ({
  theme: validTheme(hero.theme),
  backgroundProps: {
    backgroundColor: hero.backgroundColor,
    imageDesktop: hero.image,
    imageMobile: hero.imageMobile,
    videoDesktop: hero.video,
    videoMobile: hero.videoMobile,
  },
  content: (
    <DangerousHeadline
      title={hero.title}
      className={hasThreeLines(hero.title) ? "three-line-offsets" : ""}
    />
  ),
  ctas: [
    {
      text: intl.t("hero.shopall", "Shop All"),
      to: "shop/all",
    },
  ],
});

const stressReliefInternals = (hero: HomePageHero) => ({
  theme: validTheme(hero.theme),
  backgroundProps: {
    backgroundColor: null,
    imageDesktop: hero.image,
    imageMobile: hero.imageMobile,
    videoDesktop: hero.video,
    videoMobile: hero.videoMobile,
  },
  content: <DangerousHeadline title={hero.title} className="full-width" />,
  ctas: [
    {
      text: intl.t("hero.shopstress", "Shop Stress Relief"),
      to: "/products/stress-relief-bioseries",
    },
  ],
});

const promotionInternals = (hero: HomePageHeroPromotion) => ({
  theme: validTheme("default"),
  backgroundProps: {
    backgroundColor: hero.node.backgroundColor,
    imageDesktop: hero.node.backgroundImageDesktop,
    imageMobile: hero.node.backgroundImageMobile,
  },
  content: hero.content,
  ctas: hero.ctas,
});

type HomePageHeroProps = { promotionSlug?: string };
const HomePageHero = ({ promotionSlug }: HomePageHeroProps) => {
  const homePageHero = useHomePageHero();
  const homePageHeroPromotion = useHomePageHeroPromotion(promotionSlug, {
    renderContent: renderHomePageHeroContent,
  });
  const traceCampaignHero = useVariation("trace-campaign-hero", true);

  // remove when stress is released
  const stressReliefHeroTitle =
    homePageHero.video?.title === "Stress Relief Hero Video Desktop";

  const { backgroundProps, ctas, content, theme } =
    (homePageHeroPromotion && promotionInternals(homePageHeroPromotion)) ||
    (stressReliefHeroTitle && stressReliefInternals(homePageHero)) ||
    defaultInternals(homePageHero);

  const handleCtaClick = useCallback(({ text }: (typeof ctas)[0]) => {
    metrics.track("CTA Clicked", {
      location: "Hero",
      title: text,
      nonInteraction: false,
    });
  }, []);

  const newBackgroundProps = {
    backgroundColor: "#FFD600",
  };

  const pageHeroProps = traceCampaignHero
    ? newBackgroundProps
    : backgroundProps;

  return (
    <PageHero {...pageHeroProps}>
      {traceCampaignHero ? (
        <FloatingPillHero />
      ) : (
        <HeroContent ctas={ctas} onCtaClick={handleCtaClick} theme={theme}>
          {content}
        </HeroContent>
      )}
    </PageHero>
  );
};

export default HomePageHero;
